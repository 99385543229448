import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormInput, FormCheck, FormSelect } from "../../components/Base/Form";
import Button from "../../components/Base/Button";
import clsx from "clsx";
import IgeriuLogo from "../../assets/images/igeriu.svg";
import illustrationUrl from "../../assets/images/illustration.svg";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import apiRequest from "../../apiService";
import i18n from '../../i18n';

function Login() {
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await apiRequest("login", "POST", {
        email: user,
        password: pwd,
      });

      if (data.token) {
        localStorage.setItem("token", data.token);
        toast.success(t('000077'));
        navigate("/home");
      } else {
        setErrMsg(t('000078') + " " + t('000079'));
      }
    } catch (error) {
      setErrMsg(t('000080'));
      toast.error(t('000076'));
    }
  };

  // Função para mudar o idioma
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div
      className={clsx([
        "p-3 sm:px-8 relative h-screen lg:overflow-hidden bg-[#17191E] xl:bg-white dark:bg-darkmode-800 xl:dark:bg-darkmode-600",
        "before:hidden before:xl:block before:content-[''] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%] before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg] before:bg-[#17191E]/20 before:rounded-[100%] before:dark:bg-darkmode-400",
        "after:hidden after:xl:block after:content-[''] after:w-[57%] after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform after:rotate-[-4.5deg] after:bg-[#17191E] after:rounded-[100%] after:dark:bg-darkmode-700",
      ])}
    >
      <div className="container relative z-10 sm:px-10">
        <div className="block grid-cols-2 gap-4 xl:grid">
          <div className="flex-col hidden min-h-screen xl:flex">
            <a href="/" className=" pt-5 -intro-x">
              <img src={IgeriuLogo} alt="" className="w-[144px] object-cover" />
            </a>
            <div className="my-auto">
              <img
                alt="Midone Tailwind HTML Admin Template"
                className="w-1/2 -mt-16 -intro-x"
                src={illustrationUrl}
              />
              <div className="mt-10 text-4xl font-medium leading-tight text-white -intro-x w-80">
                {t('000069')}
              </div>
              <div className="mt-5 text-lg text-white -intro-x text-opacity-70 dark:text-slate-400 w-72">
                {t('000070')}
              </div>
            </div>
          </div>
          <div className="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0">
            <form
              onSubmit={handleSubmit}
              className="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto"
            >
              <h2 className="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left">
                {t('000071')}
              </h2>
              <div className="mt-2 text-center intro-x text-slate-400 xl:hidden">
                {t('000069')} {t('000070')}
              </div>
              {errMsg && <p className="text-red-500 text-center">{errMsg}</p>}
              <div className="mt-8 intro-x">
                <FormInput
                  type="text"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  className="block px-4 py-3 intro-x min-w-full xl:min-w-[470px]"
                  placeholder={t('000074')}
                  required
                />
                <FormInput
                  type="password"
                  required
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                  className="block px-4 py-3 mt-4 intro-x min-w-full xl:min-w-[470px]"
                  placeholder={t('000075')}
                />
              </div>
              <div className="flex mt-4 text-xs intro-x text-slate-600 dark:text-slate-500 sm:text-sm">
                <div className="flex items-center mr-auto">
                  <FormCheck.Input
                    id="remember-me"
                    type="checkbox"
                    className="mr-2 border"
                  />
                  <label
                    className="cursor-pointer select-none"
                    htmlFor="remember-me"
                  >
                    {t('000073')}
                  </label>
                </div>
                <a href="/">{t('000072')}</a>
              </div>
              <div className="mt-5 text-center intro-x xl:mt-8 xl:text-left">
                <Button
                  variant="dark"
                  type="submit"
                  className="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
                >
                  {t('000071')}
                </Button>
              </div>
            </form>
          </div>
        </div>

        {/* Seletor de idioma */}
        <div className="fixed bottom-0 right-0 p-4">
          <FormSelect
            onChange={(e) => changeLanguage(e.target.value)}
            defaultValue={i18n.language}
          >
            <option value="en">🇺🇸 English</option>
            <option value="es">🇪🇸 Español</option>
            <option value="pt-br">🇧🇷 Português</option>
          </FormSelect>
        </div>
      </div>
    </div>
  );
}

export default Login;
