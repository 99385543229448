const API_BASE_URL = "http://localhost/api/";

const apiRequest = async (endpoint, method = 'GET', body = null, headers = {}) => {
  const url = API_BASE_URL + endpoint;

  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers // Permite adicionar cabeçalhos extras, se necessário
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText} (status: ${response.status})`);
    }

    const data = await response.json();
    return data; // Retorna os dados da resposta

  } catch (error) {
    console.error("API request error:", error);
    throw error; // Repassa o erro para que possa ser tratado no componente
  }
};

export default apiRequest;
